import React, {forwardRef, ReactNode, useEffect} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {useIsMounted} from "@pg-mono/hooks";

import {CheckboxSize} from "../utils/CheckboxSize";
import {CheckboxDisplay} from "./CheckboxDisplay";

export type ICheckboxProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "checked" | "type" | "onChange" | "size"> & {
    name: string;
    checked: boolean | "half-checked";
    id?: string;
    className?: string;
    onChange: (name: string, checked: boolean, value: HTMLInputElement["value"]) => void;
    onAfterChange?: (name: string, checked: boolean, value: HTMLInputElement["value"]) => void;
    checkedMarkColor?: string;
    size?: CheckboxSize;
} & ILabelScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: ReactNode | string;
    "aria-labelledby"?: never;
};

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>((props, ref) => {
    const {checked, onChange, id, readOnly, labelContent, onAfterChange, className, size = "md", checkedMarkColor, ...inputProps} = props;

    const isMounted = useIsMounted();
    useEffect(() => {
        if (onAfterChange && isMounted) {
            onAfterChange?.(props.name, !!props.checked, props.value as HTMLInputElement["value"]);
        }
    }, [props.checked]);

    return (
        <ComponentContainer className={className}>
            <div css={checkboxWrapper}>
                <HiddenInput
                    {...inputProps}
                    type="checkbox"
                    checked={!!checked}
                    onChange={({target: {name, checked, value}}) => {
                        if (!readOnly) {
                            onChange(name, checked, value);
                        }
                    }}
                    id={id}
                    ref={ref}
                />
                <CheckboxDisplay checked={checked} checkedMarkColor={checkedMarkColor} readOnly={readOnly} size={size} />
            </div>
            {labelContent && (
                <label css={labelStyle} htmlFor={id}>
                    {labelContent}
                </label>
            )}
        </ComponentContainer>
    );
});

/**
 * Styles
 */
const ComponentContainer = styled.div`
    display: inline-flex;
    align-items: center;
`;

const labelStyle = css`
    margin: 0 0 0 0.4rem;
    display: flex;
    flex: 1 0;
    height: 100%;
    align-items: center;
    cursor: pointer;
`;

const checkboxWrapper = css`
    position: relative;
    cursor: pointer;
`;

const HiddenInput = styled.input`
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 20;

    & ~ div {
        outline: 0.2rem solid transparent;
        transition: ${({theme}) => `outline-color, ${theme.transition.timingFunction}, ${theme.transition.duration}`};
    }

    &:focus-visible ~ div {
        outline-color: ${({theme}) => theme.colors.info};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;
