import {IModularHousesPublicListOffer, mpPublicApiLinks} from "@pg-mono/rp-api-routes";

import {mpApi} from "../../app/api/mp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IGetModularHouseOfferPayload} from "../types/IGetModularHouseOfferPayload";

export const getModularHousesOfferListApi = mpApi.injectEndpoints({
    endpoints: (builder) => ({
        getModularHouseOfferList: builder.query<IPaginatedResponse<IModularHousesPublicListOffer>, IGetModularHouseOfferPayload>({
            query: (params) => ({
                url: mpPublicApiLinks.marketplace.modularHouses.offer.list(),
                method: "GET",
                params
            }),
            providesTags: (result) => {
                if (result) {
                    return [...result.results.map((offer) => ({type: "ModularHouseOffer" as const, id: offer.id})), "ModularHouseOffer"];
                }

                return ["ModularHouseOffer"];
            }
        })
    })
});

export const {useGetModularHouseOfferListQuery} = getModularHousesOfferListApi;
