import {ReactNode, useState} from "react";
import {css} from "@emotion/react";

import {calculateRemSize as crs, listUnStyled} from "@pg-design/helpers-css";

import {IRadioProps, Radio} from "./Radio";

interface IProps {
    name: string;
    options: IButtonRadioListOption[];
    onChange?: (value: IOptionValue) => void;
    className?: string;
    radioClassName?: string;
    optionClassName?: string;
    radioRender?: (props: IRadioProps) => JSX.Element;
}

export interface IButtonRadioListOption {
    value: IOptionValue;
    labelContent: ReactNode | string;
}

export type IOptionValue = string;

export function RadioGroup(props: IProps) {
    const {className, radioClassName, optionClassName, name, options, onChange, radioRender} = props;
    const [selectedValue, setSelectedValue] = useState<IOptionValue | null>(null);

    const onRadioChange = (_name: string, _isActive: boolean, value: IOptionValue) => {
        setSelectedValue(value as IOptionValue);

        if (onChange) {
            onChange(value as IOptionValue);
        }
    };

    return (
        <ul className={className} css={radioList}>
            {options.map((option, index) => {
                const selectionId = `ro_${name}_${option.value}`;
                const isActive = selectedValue === option.value;

                return (
                    <li key={`ro_k_${name}_${index}`} css={listItem} className={optionClassName}>
                        {radioRender ? (
                            radioRender({
                                name,
                                value: option.value,
                                id: selectionId,
                                isActive,
                                onChange: (name, checked, value) => {
                                    onRadioChange(name, checked, value as IOptionValue);
                                },
                                labelContent: option.labelContent,
                                className: radioClassName
                            })
                        ) : (
                            <Radio
                                name={name}
                                id={selectionId}
                                value={option.value}
                                isActive={isActive}
                                onChange={(name, checked, value) => {
                                    onRadioChange(name, checked, value as IOptionValue);
                                }}
                                labelContent={option.labelContent}
                                className={radioClassName}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    );
}

//  Styles
const radioList = css`
    ${listUnStyled};
    margin: 0;
    display: flex;
    gap: ${crs(1)};
`;

const listItem = css`
    flex: 1 1 0;
`;
